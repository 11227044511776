$s-black: #000000;

$s-ui-1: #0b0b0c;
$s-ui-2: #323538;
$s-ui-3: #828282;
$s-ui-4: #c4c4c4;
$s-ui-5: #f2f2f2;
$s-ui-6: #ffffff;
$s-ui-7: #00b5b5;
$s-ui-8: #00cbcb;
$s-ui-9: #FF3E6C;
$s-ui-11: #e85050;
$s-ui-12: #fadc28;
$s-ui-13: #3fa9f5;
$s-ui-21: #f751fa;
$s-ui-22: #4c0fd2;
$s-ui-23: #45f8d8;
$s-ui-26: #307FE2;
$s_ui_27: #64C850;


$s-ui-5-new: #EDEDED;
$s-ui-6-new: #F7F7F7;

$s-ui-tex-06: #307FE2;

$s-ui-rp-01: #ffc43b;
$s-ui-rp-02: #9ea60a;
$s-ui-rp-03: #ccc;

$s-ui-sa-01: #4E0FD2;
$s-ui-sa-02: #7142FF;

$s-ui-sf-01: #D90368;
$s-ui-sf-02: #FF3494;

$shadow1: 1px 1px 1px rgba(69, 248, 216, 0.2),
2px 2px 4px rgba(76, 15, 210, 0.2),
inset 1px 1px 1px rgba(247, 81, 250, 0.05),
inset 3px 3px 4px rgba(255, 255, 255, 0.3);
