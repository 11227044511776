// This is not the right way of doing this. Replace when we create a password field in UIKit
.password-strength-group {
  .password-strength-meter {
    width: 100%;
    transition: height 0.3s;

    display: flex;
    justify-content: stretch;
    margin-top: 4px;

    .meter-block {
      height: 8px;
      background: $s-ui-rp-03;
      border-radius: 15px;
      margin-right: 6px;
      flex-grow: 1;

      &:last-child {
        margin: 0;
      }
    }
  }

  .message-item {
    font-size: 12px;
    float: right;
    margin-top: 3px;
    transition: opacity 0.2s;
  }

  .password-strength-message {
    height: 1em;
    text-align: right;
    transition: all 0.5s;
    margin-top: 3px;

    position: relative;

    .message-item {
      font-size: 12px;
      position: absolute;
      right: 0;
      opacity: 0;
      transition: opacity 0.2s;
    }
  }

  @for $i from 1 through 8 {
    &[data-strength='#{$i}'] {
      .meter-block:nth-child(-n + #{$i}) {
        @if ($i == 1) {
          background: $s-ui-9;
        } @else if ($i == 2) {
          background: $s-ui-9;
        } @else if ($i == 3) {
          background: $s-ui-rp-01;
        } @else if ($i == 4) {
          background: $s-ui-rp-01;
        } @else if ($i == 5) {
          background: $s-ui-rp-02;
        } @else if ($i == 6) {
          background: $s-ui-rp-02;
        } @else if ($i == 7) {
          background: $s_ui_27;
        } @else if ($i == 8) {
          background: $s_ui_27;
        }
      }

      .message-item:nth-child(#{$i}) {
        opacity: 1;
      }
    }
  }
}
